import React from "react";

const Youtube = () => {
	return (
		<>
			<i className="bx bxl-youtube"></i>
		</>
	);
};

export default Youtube;
