import styled from "styled-components";

export const CategoryBoxContainer = styled.div`
      background-color: #f0f1f6;
      padding: 80px 0px;

      .container-sm {
            max-width: 1170px;
      }

      @media screen and (max-width: 568px) {
            .row {
                  display: none;
            }
      }
`;
