import styled from "styled-components";

export const CommonContainer = styled.div`
      padding: 50px 0px;

      @media screen and (max-width: 568px) {
            padding-top: 20px;
      }
`;
export const CommonWrapper = styled.div`
      padding: 0px 30px;

      @media screen and (max-width: 568px) {
            padding: 0 20px;
      }
`;
