import { Container } from "react-bootstrap";
import styled from "styled-components";

export const SisterConcernItemContainer = styled(Container)``;
export const SisterConcernItemImage = styled.div``;
export const SisterConcernDetail = styled.div``;
export const SisterConcernHeading = styled.div``;

export const SisterConcernDesc = styled.p`
      color: #555;
      font-size: 15px;
      margin-bottom: 2rem;
      margin-top: 12px;

      ul {
            li {
                  color: #666;
                  font-size: 15px;
            }
      }
`;
