import React from "react";

const ContactIframe = () => {
      return (
            <>
                  <iframe
                        src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d56524.010616866246!2d85.3197268!3d27.6941002!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x39eb19810f65f8eb%3A0x46b3c518af87085f!2sSigma%20Technologies%20Pvt.%20Ltd.%20St.%20Xavier%20College%20Aspen%20Marg%20Kathmandu%2044600!3m2!1d27.694100199999998!2d85.3197268!5e0!3m2!1sen!2snp!4v1704438441648!5m2!1sen!2snp"
                        width="100%"
                        height="450"
                        style={{ border: "0" }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
            </>
      );
};

export default ContactIframe;
