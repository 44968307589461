import React from "react";

const Twitter = () => {
      return (
            <>
                  <i className="bx bxl-twitter"></i>
            </>
      );
};

export default Twitter;
