import React from "react";

const Linkedin = () => {
	return (
		<>
			<i className="bx bxl-linkedin-square"></i>
		</>
	);
};

export default Linkedin;
