import { Form } from "react-bootstrap";
import styled from "styled-components";

export const ProductForm = styled(Form)`
      .form-control {
            &:focus {
                  box-shadow: none;
            }
      }
`;
