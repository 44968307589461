import React from "react";

const Instagram = () => {
	return (
		<>
			<i className="bx bxl-instagram"></i>
		</>
	);
};

export default Instagram;
