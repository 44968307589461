import React from "react";

const ExpandChevron = () => {
      return (
            <>
                  <span className="material-symbols-outlined">expand_more</span>
            </>
      );
};

export default ExpandChevron;
