import React from 'react'

const Facebook = () => {
  return (
    <>
      <i className='bx bxl-facebook' ></i>
    </>
  )
}

export default Facebook
