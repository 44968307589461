import React from "react";
import {
      DropDownContainer,
      DropDownList,
      DropDownSubContainer,
} from "../../../../../frontend/layouts/header/styles";
import DropDownItem from "../DropDownItem";
import MenuItem from "./MenuItem";

const DropDownBox = ({ category, about, categories, title, loading }) => {
      let categoryList = [];
      if (title === "Shop") {
            categoryList = categories;
      } else {
            categoryList = category;
      }

      return (
            <>
                  <DropDownContainer
                        className={`${
                              title === "Shop" ? "dropdown__content" : ""
                        }`}
                  >
                        <DropDownList>
                              {about ? (
                                    <>
                                          {categoryList?.map((cat, index) => (
                                                <DropDownItem
                                                      title={
                                                            cat.subtitle !==
                                                                  "Water Filtration" &&
                                                            cat.subtitle
                                                                  ? cat.subtitle
                                                                  : cat.title
                                                      }
                                                      slug={cat.slug}
                                                      index={index}
                                                      about={about}
                                                      cat={cat}
                                                >
                                                      {cat?.subCategory && (
                                                            <DropDownSubContainer>
                                                                  <DropDownList>
                                                                        {cat?.subCategory.map(
                                                                              (
                                                                                    sub,
                                                                                    index
                                                                              ) => (
                                                                                    <DropDownItem
                                                                                          title={
                                                                                                sub.title
                                                                                          }
                                                                                          slug={
                                                                                                sub.slug
                                                                                          }
                                                                                          index={
                                                                                                index
                                                                                          }
                                                                                    />
                                                                              )
                                                                        )}
                                                                  </DropDownList>
                                                            </DropDownSubContainer>
                                                      )}
                                                </DropDownItem>
                                          ))}
                                    </>
                              ) : (
                                    <>
                                          {!loading ? (
                                                categories.length > 0 ? (
                                                      <div className="define">
                                                            <ul>
                                                                  {categories?.map(
                                                                        (
                                                                              item,
                                                                              index
                                                                        ) => (
                                                                              <li
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <MenuItem
                                                                                          title={
                                                                                                item?.title
                                                                                          }
                                                                                          slug={
                                                                                                item?.slug
                                                                                          }
                                                                                          cat={
                                                                                                item
                                                                                          }
                                                                                    />
                                                                                    {item
                                                                                          ?.subCategory
                                                                                          ?.length >
                                                                                          0 && (
                                                                                          <ul>
                                                                                                {item?.subCategory?.map(
                                                                                                      (
                                                                                                            subItem,
                                                                                                            key
                                                                                                      ) => (
                                                                                                            <li
                                                                                                                  key={
                                                                                                                        key
                                                                                                                  }
                                                                                                            >
                                                                                                                  <MenuItem
                                                                                                                        title={
                                                                                                                              subItem?.title
                                                                                                                        }
                                                                                                                        subCategory={
                                                                                                                              true
                                                                                                                        }
                                                                                                                        slug={
                                                                                                                              subItem?.slug
                                                                                                                        }
                                                                                                                        cat={
                                                                                                                              subItem
                                                                                                                        }
                                                                                                                  />
                                                                                                            </li>
                                                                                                      )
                                                                                                )}
                                                                                          </ul>
                                                                                    )}
                                                                              </li>
                                                                        )
                                                                  )}
                                                            </ul>
                                                      </div>
                                                ) : null
                                          ) : null}
                                    </>
                              )}
                        </DropDownList>
                  </DropDownContainer>
            </>
      );
};

export default DropDownBox;
